

const modal = {
    elements: {
        $modals: document.querySelectorAll('.js-modal'),
        $modalToggles: document.querySelectorAll('.js-modal-toggle'),
    },
    init() {
        this.elements.$modalToggles.forEach((modalToggle) => {
            modalToggle.addEventListener('click', (e) => {
                e.preventDefault();
                const handle = e.currentTarget.dataset.modalHandle;
                const $targets = document.querySelectorAll(`[data-modal-handle="${handle}"]`);
                $targets.forEach(($target) => {
                    $target.classList.toggle('is-active');
                });
            });
        });
    }
};

export default modal;
