<template>
    <div class="glossaryWrapper-popup" :style="'top:' + targetTop + 'px'">
        <button class="close" @click="closePopup">
            <span class="block w-5">
                <div v-html="Close" class="w-full h-auto"></div>
            </span>
            <span class="sr-only">Close</span>
        </button>
        <h3 class="headline-04">{{ title }}</h3>
        <span aria-hidden="true" class="arrow" :style="'left:' + targetLeft +'px'"></span>
        <div v-html="copy"></div>

        <GlossaryToggle :currentuser="currentuser" @glossaryDisabled="glossaryDisabled" />
    </div>
</template>

<script setup>
    import axios from 'axios';
    import { ref, onMounted } from 'vue';
    import ToolTipIcon from '/src/svgs/Info.svg?raw';
    import Modal from './Modal.vue';
    import GlossaryToggle from './GlossaryToggle.vue';

    const modalOpen = ref(false);
    const csrftoken = ref({ name: '', value: ''});
    const emit = defineEmits(['close', 'glossaryDisabled']);

    const renewCsrfToken = async () => {
        csrftoken.value = await fetch('/actions/blitz/csrf/token')
            .then(result => { return result.text(); })
            .then(result => {
                return {
                    name: 'CRAFT_CSRF_TOKEN',
                    value: result
                }
            });
    };

    import Close from '/src/svgs/Close.svg?raw';

    const props = defineProps({
        title: {
            type: String,
            required: true
        },
        copy: {
            type: String,
            required: true
        },
        targetTop: {
            type: Number,
            required: true
        },
        targetLeft: {
            type: Number,
            required: true
        },
        currentuser: {
            type: Object,
            required: false,
            default: null
        }
    });

    const activeGlossary = ref(true);

    const glossaryDisabled = () => {
        emit('glossaryDisabled');
        closePopup();
    };

    const closePopup = () => {
        emit('close');
    };

    const apiCheckGlossaryStatus = async () => {
        await renewCsrfToken();

        const response = await axios.post('/actions/readinguniverse/user/get-glossary-status', {
            [csrftoken.value.name]: csrftoken.value.value
        }).catch((error) => {
            console.error('Error checking glossary highlights', error);
        }).finally(() => {
        });

        return response.data.activeGlossary;
    };

    onMounted(async () => {
        activeGlossary.value = await apiCheckGlossaryStatus();
    });
</script>

