<template>
    <article class="relative flex flex-col justify-between gap-6 p-6 bg-white rounded-lg shadow-popover animation-card | taxonomic-card">

        <!-- If card as a link, wrap the card content in an anchor tag -->
        <a v-if="card.url && card.handle != 'search'" :href="card.url" :aria-label="'Read more about ' + card.title">
            <CardHeading
                :handle="handle"
                :card="card"
                :classnames="classnames?.inner"
                :background-color="color"
                :label-text-color="getLabelTextColor(color)"
            />
            <CardInner
                :handle="handle"
                :card="card"
                :classnames="classnames?.inner"
                :background-color="color"
                :label-text-color="getLabelTextColor(color)"
            />
        </a>

        <!-- If card doesn't have a link, display the card content without an anchor tag -->
        <div v-else>
            <CardHeading
                :handle="handle"
                :card="card"
                :classnames="classnames?.inner"
                :background-color="color"
                :label-text-color="getLabelTextColor(color)"
            />
            <a v-if="card.url && card.handle == 'search'" :href="card.url">
                <CardInner
                    :handle="handle"
                    :card="card"
                    :classnames="classnames?.inner"
                    :background-color="color"
                    :label-text-color="getLabelTextColor(color)"
                />
            </a>
            <div v-else>
                <CardInner
                    :handle="handle"
                    :card="card"
                    :classnames="classnames?.inner"
                    :background-color="color"
                    :label-text-color="getLabelTextColor(color)"
                />
            </div>
        </div>

        <!-- Favorite button, not functional yet -->
        <div v-if="checkSetting(card?.settings?.showFavorite || card?.sectionType == 'skillExplainer')">
            <button
                class="button button--transparent"
                aria-label="Add to favorites"
                @click="apiAddToFavorites(card.id)"
                v-if="currentuser"
            >
                <span class="block w-2">
                    <span class="sr-only">{{ isFavorite ? 'In Favorites' : 'Add to Favorites' }}</span>
                    <Icon dir="/assets/icons/" name="heart" :class="[
                        'w-8 h-6 transition-all duration-150 fill-transparent stroke-red-default stroke-2',
                        isFavorite ? '!fill-red-default' : 'hover:scale-125'
                    ]" />
                </span>
            </button>

            <button
                class="js-tip button button--transparent w-9"
                aria-label="Add to favorites"
                :data-tip-content="tip"
                role="tooltip"
                ref="tipButton"
                v-else
            >
                <span class="block w-2">
                    <span class="sr-only">Add to Favorites</span>
                    <Icon dir="/assets/icons/" name="heart"
                        class="w-8 h-6 transition-all duration-150 fill-transparent stroke-red-default stroke-2 hover:scale-125"
                    />
                </span>
            </button>
        </div>
    </article>
</template>

<style>
    .taxonomic-card {
        --blue-4: #4fbdff;
        --blue-6: #e5f6ff;
        --blue-8: #ccecff;
        --blue-9: #f2faff;


        --orange-2: #f8c587;
        --orange-7: #f39627;

        --white: #fff;
        --white-1: #fef3e7;

        --yellow-4: #ffdc81;

        --purple-1: #af86ec;
        --purple-2: #f7f3fd;

        --green-3: #9ec134;
        --green-4: #f7fbed;
    }
</style>

<script setup>

    /*
        We're using a Card Inner component to display the card content.
        This separation exists because we may want to use the card content
        without the card link in some cases. This also helps us have the "favorite"
        button in the Card component, which should exist outside the link.
    */

    import { ref, onMounted, watch } from 'vue';

    import axios from 'axios';
    const csrftoken = ref({ name: '', value: ''});
    const isFavorite = ref(false);
    import Tips  from '../../utils/tips.js';

    import CardHeading from './CardHeading.vue';
    import CardInner from './CardInner.vue';
    import Heart from '/src/svgs/Heart.svg?raw';
    import Icon from '../pieces/Icon.vue';

    const tipButton = ref(null);
    import { store } from '../../store.js';

    const tip = [
        '<span class="text-xs leading-snug block">',
            '<p><a class="underline" href="/login">Sign in</a> to save your favorites.</p>',
            '<p>Don\'t have an account? <a class="underline" href="/account-creation">It\'s easy and free to sign up</a>!</p>',
        '</span>'
    ].join('');

    const renewCsrfToken = async () => {
        csrftoken.value = await fetch('/actions/blitz/csrf/token')
            .then(result => { return result.text(); })
            .then(result => {
                return {
                    name: 'CRAFT_CSRF_TOKEN',
                    value: result
                }
            });
    };

    const props = defineProps({
        card: {
            type: Object,
            required: true
        },
        handle: {
            type: String,
            default: 'allBlogPosts'
        },
        currentuser: {
            type: Object,
            default: () => {}
        },
        classnames: {
            type: [Array, Object],
            default: () => []
        },
        color: {
            type: String,
            require: false,
            default: "var(--orange-2)"
        }
    });

    /* Function to check if a setting is defined */
    const checkSetting = (setting) => {
        if (setting === undefined) {
            return false;
        }

        return setting;
    };

    /*     C O L O R S     *
        *     K I C K E R     *
        Taxonomic Cards have a little colored bar at the top that indicates where in the Taxonomy
        they belong. That color comes from the Taxonomy or Skill Explainer in their Locator field.
        Those values, in turn, are stored in a field on those entries... as CSS variables for
        colors. So, in order to get and use the colors correctly, we need to have those variables
        set and assigned in the CSS. From there, we can apply the color directly to that bar, the
        "Kicker." Then we can determine what color the text WITHIN that Kicker should be in order
        for it to be visible, via the `getLabelTextColor` function.

        *     L A B E L S   A N D   I C O N S     *
        Taxonomic Cards ALSO have Labels that are color-coded to coordinate with their Taxonomy
        color. The Labels can either be solid color or white with a colored border and Icon. Note
        that the color HERE is NOT the SAME as the color dictated in the related Taxonomy entry,
        but is a SECOND shade that complements it. In the `getColor` function, we use the value
        returned from the CMS for the Taxonomy entry to figure out which color to use.
    */
    function getLabelTextColor(bgColor) {
        let lightText = [
            'var(--blue-4)',
            'var(--orange-7)',
            'var(--purple-1)',
            'var(--green-3)'
        ];
        let textColor = 'text-black';

        if (lightText.includes(bgColor)) {
            textColor = 'text-white';
        }

        return textColor;
    };

    function getColor(bgColor) {
        let blue = ['var(--blue-4)', 'var(--blue-6)', 'var(--blue-8)', 'var(--blue-9)'];
        let orange = ['var(--orange-2)', 'var(--orange-7)'];
        let white = ['var(--white)', 'var(--white-1)'];
        let yellow = ['var(--yellow-4)'];
        let purple = ['var(--purple-1)', 'var(--purple-3)'];
        let green = ['var(--green-3)', 'var(--green-4)'];
        let color = '';

        // For each color group, see if this color matches and, if so, assign it the right colors, also based on type
        if (blue.includes(bgColor)) {
            color = 'blue-default';
        } else if (orange.includes(bgColor)) {
            color = 'orange-5';
        } else if (white.includes(bgColor)) {
            color = 'gray-3';
        } else if (yellow.includes(bgColor)) {
            color = 'yellow-default';
        } else if (purple.includes(bgColor)) {
            color = 'purple-3';
        } else if (green.includes(bgColor)) {
            color = 'green-5';
        }

        return color;
    };

    function getLabelClasses(bgColor, type) {
        let color = this.getColor(bgColor);
        let classes = 'rounded-md uppercase font-bold ';

        classes += type == 'solid' ? ` bg-${color} text-white` : ` border-${color} border-2 fill-${color}`;

        return classes;
    };

    const apiAddToFavorites = async (id) => {
        await renewCsrfToken();

        const response = await axios.post('/actions/readinguniverse/user/favorites-add/', {
            [csrftoken.value.name]: csrftoken.value.value,
            id: id
        })

        .catch((error) => {
            console.error('Error fetching download entries:', error);
        }).finally(() => {
        });

        isFavorite.value = response.data?.isFavorite || false;

        return response.data || [];
    }

    watch(
        () => store.favorites,
        (newFavorites, oldFavorites) => {
            let exists = Object.values(newFavorites).includes(props.card.id);
            isFavorite.value = exists;
        }
    );


    onMounted(async () => {
        Tips.element(tipButton.value);
    });
</script>
