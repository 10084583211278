

<template>
    <div class="max-lg:right-0 fixed h-fit bottom-0 left-0 z-20 lg:top-1/2 lg:transform lg:-translate-y-full" v-if="allowShareThis()">
        <div class="max-lg:flex max-lg:justify-center" id="share-this">
            <div class="max-lg:max-w-96 max-lg:w-full max-lg:justify-center flex lg:flex-col">
                <button class="max-lg:aspect-square max-lg:w-1/5 flex justify-center items-center bg-red-default text-white lg:w-11 lg:h-11"
                    aria-label="Add to favorites"
                    @click="apiAddToFavorites(entryId)"
                    v-if="entryId && currentUser"
                >
                    <span class="sr-only">{{ isFavorite == 'true' ? 'In Favorites' : 'Add to Favorites' }}</span>
                    <Icon dir="/assets/icons/" name="heart"
                        class="stroke-white stroke-2 w-7 h-5 transition-all duration-150"
                        :class="['', isFavorite === 'true' ? 'fill-white' : 'fill-transparent']"
                    />
                </button>

                <button
                    class="js-tip max-lg:aspect-square max-lg:w-1/5 flex justify-center items-center bg-red-default text-white lg:w-11 lg:h-11"
                    aria-label="Add to favorites"
                    :data-tip-content="tip"
                    role="tooltip"
                    ref="tipButton"
                    v-else
                >
                    <span class="sr-only">Add to Favorites</span>
                    <Icon dir="/assets/icons/" name="heart"
                        class="stroke-white stroke-2 w-7 h-5 transition-all duration-150 fill-transparent"
                    />
                </button>


                <button
                    v-for="network in networks" :key="network"
                    class="st-custom-button max-lg:aspect-square max-lg:w-1/5 flex justify-center items-center lg:w-11 lg:h-11"
                    :data-network="network"
                    :style="{ 'backgroundColor': networkColors[network] }">
                    <span class="sr-only">{{ network }}</span>

                    <div class="text-white">
                        <Icon dir="/assets/icons/" name="facebook-f" class="fill-white h-6" v-if="network === 'facebook'"></Icon>
                        <Icon dir="/assets/icons/" name="twitter" class="fill-white h-6" v-if="network === 'twitter'"></Icon>
                        <Icon dir="/assets/icon-selector/" name="print" class="fill-white h-6" v-if="network === 'print'"></Icon>
                        <Icon dir="/assets/icons/" name="email" class="fill-white h-6" v-if="network === 'email'"></Icon>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import Icon from './pieces/Icon.vue';

    import { ref, onMounted, watch } from 'vue';
    import axios from 'axios';
    import Tips  from '../utils/tips.js';

    const tip = [
        '<span class="text-xs leading-snug block">',
            '<p><a class="underline" href="/login">Sign in</a> to save your favorites.</p>',
            '<p>Don\'t have an account? <a class="underline" href="/account-creation">It\'s easy and free to sign up</a>!</p>',
        '</span>'
    ].join('');

    const props = defineProps({
        shareThisPropertyId: {
            type: String,
            required: true
        },
        entryId: {
            type: String,
            required: false
        },
        isFavorite: {
            type: String,
            required: false
        },
        currentUser: {
            type: String,
            required: false
        }
    });

    const csrftoken = ref({ name: '', value: ''});
    const isFavorite = ref(props.isFavorite || 'false');
    const networks = ['facebook', 'twitter', 'print', 'email'];
    const tipButton = ref(null);

    const networkColors = {
        facebook: '#3e5d95',
        twitter: '#33a5ee',
        print: '#758a8d',
        email: '#848484',
    };

    const renewCsrfToken = async () => {
        csrftoken.value = await fetch('/actions/blitz/csrf/token')
            .then(result => { return result.text(); })
            .then(result => {
                return {
                    name: 'CRAFT_CSRF_TOKEN',
                    value: result
                }
            });
    };

    const allowShareThis = () => {
        return ![
            '/login',
            '/account-creation',
            '/oauth'
        ].includes(window.location.pathname);
    };

    const apiAddToFavorites = async (id) => {
        await renewCsrfToken();

        const response = await axios.post('/actions/readinguniverse/user/favorites-add/', {
            [csrftoken.value.name]: csrftoken.value.value,
            id: id
        })
        .catch((error) => {
            //console.error('Error fetching download entries:', error);
        }).finally(() => {
            //console.log('Favorites updated');
        });

        let newFavorite = response.data?.isFavorite ? 'true' : 'false'
        isFavorite.value = newFavorite;

        return response.data || [];
    }

    onMounted(() => {
        if (!allowShareThis()) {
            return;
        }

        Tips.element(tipButton.value);

        // Load the ShareThis script.
        const script = document.createElement('script');
        script.src = 'https://platform-api.sharethis.com/js/sharethis.js#property=' + props.shareThisPropertyId + '&product=sop';
        script.async = true;
        document.body.appendChild(script);

        // Remove the ShareThis script when the component is unmounted.
        return () => {
            document.body.removeChild(script);
        };
    });

</script>
