<template>
    <div v-if="currentuser" class="">
        <form class="form flex items-center gap-4" @submit.prevent="toggleGlossary">
            <label for="glossaryHighlights" class="text-xs uppercase font-bold">
                Interactive Glossary Highlights
            </label>
            <span class="form-switch">
                <input type="checkbox" id="glossaryHighlights" v-model="activeGlossary" :checked="activeGlossary" @change="toggleGlossary">
                <span aria-hidden="true"></span>
            </span>

            <!-- Tooltip -->
            <div
                class="js-tip"
                :data-tip-content="tip"
                role="tooltip"
            >
                <span class="block w-4 h-4 text-blue-default cursor-pointer">
                    <div v-html="ToolTipIcon" class="w-full h-auto"></div>
                </span>

                <span class="sr-only">Info</span>
            </div>
        </form>
    </div>

    <teleport to="body">
        <Modal v-if="modalOpen" :class="{ 'is-active': modalOpen }">
            <div class="grid gap-4">
                <h2 class="!heading-03 text-blue-default">Are you sure that you want to turn off the interactive glossary?</h2>

                <div class="richtext">
                    <p>When it's on, the interactive glossary will highlight terms from the glossary as they appear across the site. You can hover to get definitions and additional context. If you turn the interactive glossary off now, you can turn it back on by visiting the Glossary Page.</p>
                </div>

                <div class="flex justify-center gap-4">
                    <button class="button button--rounded button--large" @click="disableGlossary">Turn it off</button>
                    <button class="button button--rounded button--large button--outline" @click="modalOpen = !modalOpen; activeGlossary = true">Keep it on</button>
                </div>
            </div>
        </Modal>
    </teleport>
</template>

<script setup>
    import axios from 'axios';
    import { ref, onMounted } from 'vue';
    import Tips  from '../utils/tips.js';

    import ToolTipIcon from '/src/svgs/Info.svg?raw';
    import Modal from './Modal.vue';

    const modalOpen = ref(false);
    const csrftoken = ref({ name: '', value: ''});
    const emit = defineEmits(['close', 'glossaryDisabled']);

    const renewCsrfToken = async () => {
        csrftoken.value = await fetch('/actions/blitz/csrf/token')
            .then(result => { return result.text(); })
            .then(result => {
                return {
                    name: 'CRAFT_CSRF_TOKEN',
                    value: result
                }
            });
    };

    const tip = '<span class="text-xs leading-snug block">You can turn the interactive glossary highlights off here. Visit the <a href="/glossary" class="link-wrapper internal" disabled="false">glossary page</a> to turn them back on.</span>';


    const props = defineProps({
        currentuser: {
            type: Object,
            required: false,
            default: null
        }
    });
    const activeGlossary = ref(props.currentuser?.activeGlossary ?? true);

    const apiCheckGlossaryStatus = async () => {
        await renewCsrfToken();

        const response = await axios.post('/actions/readinguniverse/user/get-glossary-status', {
            [csrftoken.value.name]: csrftoken.value.value
        }).catch((error) => {
            console.error('Error checking glossary highlights', error);
        }).finally(() => {
        });

        return response.data.activeGlossary;
    };


    const apiToggleGlossary = async (status) => {
        await renewCsrfToken();

        const response = await axios.post('/actions/readinguniverse/user/set-glossary-tips', {
            [csrftoken.value.name]: csrftoken.value.value,
            activeGlossary: status
        }).catch((error) => {
            console.error('Error toggling glossary highlights', error);
        }).finally(() => {
        });
    };

    const toggleGlossary = async () => {
        if (activeGlossary.value == true) {
            apiToggleGlossary(true);
        } else {
            modalOpen.value = true;
        }
    };

    const disableGlossary = async () => {
        await renewCsrfToken();
        apiToggleGlossary(false);
        modalOpen.value = false;
        emit('glossaryDisabled');  // Emit the event
    };


    onMounted(async () => {
        activeGlossary.value = await apiCheckGlossaryStatus();
        Tips.init();
    });
</script>

