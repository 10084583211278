

const tabs = {
    elements: {
        players: null,
        tabs: '.js-tabs-trigger',
        tabContents: '.js-tabs-content',
    },
    init(players) {
        const $tabs = document.querySelectorAll(this.elements.tabs);
        // Optional Plyr players support
        if (players) {
            this.elements.players = players;
        }

        $tabs.forEach((tab, index) => {
            const target = tab.getAttribute('data-tab-target');
            tab.addEventListener('click', () => {
                this.toggleTab(target);
            });
        });
    },
    toggleTab(target) {
        const $tabs = document.querySelectorAll(this.elements.tabs);
        const $tabContents = document.querySelectorAll(this.elements.tabContents);
        $tabs.forEach((tab, i) => {
            tab.classList.remove('is-active');
        });

        $tabContents.forEach((tabContent, i) => {
            tabContent.classList.remove('is-active');
        });

        // Check if contains * in target, then toggle all tabs that match the pattern
        if (target.includes('*')) {
            const targetPattern = target.replace('*', '');
            $tabs.forEach((tab, i) => {
                const tabTarget = tab.getAttribute('data-tab-target');
                if (tabTarget.includes(targetPattern)) {
                    document.getElementById(tabTarget)?.classList.add('is-active');
                }
            });
        } else {
            document.getElementById(target).classList.add('is-active');
        }

        document.querySelector(`[data-tab-target="${target}"]`).classList.add('is-active');


        // Pause all plyr players
        if (this.elements.players) {
            this.elements.players.forEach((player) => {
                player.pause();
            });
        }
    }
};

export default tabs;
