// import Swiper JS
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper styles
import 'swiper/css';

const slider = {
    elements: {
        $sliders: [],
        sliders: document.querySelectorAll('.js-slider'),
    },
    init() {
        this.elements.sliders.forEach((slider) => {
            const swiper = new Swiper(slider, {
                modules: [Navigation, Pagination],
                slidesPerView: 1,
                spaceBetween: 10,
                loop: true,
                navigation: {
                    nextEl: slider.querySelector('.swiper-button-next'),
                    prevEl: slider.querySelector('.swiper-button-prev'),
                },
                pagination: {
                    el: slider.querySelector('.swiper-pagination'),
                    bulletClass: 'w-3 h-3 border border-gray-7 bg-orange-8 rounded-full cursor-pointer',
                    bulletActiveClass: 'bg-orange-default !border-orange-default',
                    bulletElement: 'button',
                    clickable: true,
                },
            });

            this.elements.$sliders.push(swiper);
        });
    },
};

export default slider;
