<template>
    <!-- Group -->
    <section v-for="group in this.groups" :key="group.id" class="relative">
        <!-- Title, Copy, and Button -->
        <div class="flex flex-row flex-wrap md:flex-nowrap justify-between items-center pb-8">
            <div class="flex-1">
                <h2 class="heading-02">{{ group.title }}</h2>
                <div class="richtext" v-html="group.copy"></div>
            </div>

            <div class="flex-initial">
                <a :href="group.url" class="button button--rounded">View All{{ type == 'support' ? '' : ' ' + group.title }}</a>
            </div>
        </div>

        <!-- Results -->
        <div class="px-4 -mx-4 overflow-hidden" v-if="group.results.length > 0">
            <swiper
                :modules="modules"
                :slides-per-view="4"
                :slides-per-group="4"
                :space-between="24"
                :navigation="{
                    nextEl: `#slider-${group.id}-next`,
                    prevEl: `#slider-${group.id}-prev`
                }"
                :pagination="{
                    bulletClass: 'slider-bullet',
                    bulletActiveClass: 'slider-bullet-active',
                    bulletElement: 'button',
                    clickable: true,
                    el: `#slider-${group.id}-pagination`
                }"
                :class="'slider !pb-10'"
            >
                <swiper-slide v-for="(item, index) in group.results" :key="item.id" :virtualIndex="index" :class="'slider-slide'">
                    <Card :card="{
                        ...item,
                        settings: {
                            ...item.settings,
                            hideCta: true
                        }
                    }" :color="group.backgroundColor" />
                </swiper-slide>

                <SliderArrow direction='next' :id="`slider-${group.id}-next`" />
                <SliderArrow direction='prev' :id="`slider-${group.id}-prev`" />
                <nav class="swiper-pagination" :id="`slider-${group.id}-pagination`"></nav>
            </swiper>
        </div>

        <!-- No Results -->
        <div class="w-full" v-else>
            <div class="grid gap-6 p-6 bg-white rounded-lg shadow-popover">
                <h2 class="heading-04">No results found.</h2>
                <p class="text-lg">
                    We're sorry! We are a growing site, and we'll be posting new resources regularly. Please check back often! Or you could
                    <a :href="this.newsletterUrl" target="_blank" class="underline"> sign up for email updates</a> about Reading Universe.
                </p>
            </div>
        </div>
    </section>
</template>

<style>
    .slider {
        overflow: visible;
    }

    .swiper-slide {
        height: auto;
        display: flex;
        justify-content: stretch;
        align-items: stretch;
    }

    .slider-bullet {
        width: .75rem;
        height: .75rem;
        border-radius: 9999px;
        cursor: pointer;
        background: #fef3e7;
        border: 1px solid #bcbec0;
        margin: 0 .5rem;
    }

    .slider-bullet-active {
        background: #f18a0f;
        border: 1px solid #f18a0f;
    }
</style>

<script>
    import Card from '../cards/Card.vue';
    import SliderArrow from '../pieces/SliderArrow.vue';
    // import Swiper core and required modules
    import { Navigation, Pagination, A11y, Virtual } from 'swiper/modules';

    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';

    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    import 'swiper/css/scrollbar';
    import 'swiper/css/virtual';

    export default {
        props: {
            groups: {
                type: [Object, Array],
                require: false,
                default: []
            },
            user: {
                type: Number,
                require: false
            },
            getResults: {

            },
            type: {
                type: String,
                require: true,
                default: 'module'
            }
        },
        components: {
            Card,
            Swiper,
            SwiperSlide,
            SliderArrow
        },
        data() {
            return {
                newsletterUrl: document.location.origin + '/newsletter',
                modules: [Navigation, Pagination, A11y, Virtual],
            };
        },
        methods: {},
        mounted(){
        }
    }
</script>
