<template>
    <div>
        <div ref="container" v-html="content">
        </div>

        <!-- Glossary Popup: Show when a glossary item is clicked -->
        <GlossaryPopup v-if="activeGlossaryItem"
            :title="activeGlossaryItem.title"
            :copy="activeGlossaryItem.copy"
            :targetLeft="activeGlossaryItem.targetLeft"
            :targetTop="activeGlossaryItem.targetTop"
            :currentuser="currentuser"
            @close="activeGlossaryItem = null"
            @glossaryDisabled="handleGlossaryDisabled"
        />
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import GlossaryPopup from './GlossaryPopup.vue';

const hightlightedMatches = [];

const excludedTags = ['A', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

const props = defineProps({
    html: {
        type: String,
        default: ''
    },
    glossary: {
        type: Array,
        default: () => []
    },
    currentuser: {
        type: Object,
        default: null
    }
});

const container = ref(null);

// Reference to the content div
const content = ref(null);

// Reference to the currently active glossary item
const activeGlossaryItem = ref(null);

const originalContent = ref('');
const glossaryEnabled = ref(true);

// Helper function to decode HTML entities
const decodeHtmlEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
};

// Helper function to escape special characters for regex
const escapeRegex = (string) => {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

// Function to underline glossary terms within the content
const underlineMatches = () => {
    const glossaryTerms = props.glossary
        .flatMap(item => [item.title, ...(item.variations ?? [])])
        .sort((a, b) => b.length - a.length);

    if (content.value) {
        let htmlContent = content.value;
        let decodedHtmlContent = decodeHtmlEntities(htmlContent);

        // Create a temporary DOM element to parse the HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = decodedHtmlContent;

        // Function to process text nodes
        const processTextNode = (node) => {
            const text = node.textContent;
            let result = '';
            let lastIndex = 0;
            let matches = [];
            let processedTerms = new Set();

            glossaryTerms.forEach(term => {
                if (processedTerms.has(term.toLowerCase())) return;

                const escapedTerm = escapeRegex(term);
                const regex = new RegExp(`\\b${escapedTerm}\\b`, 'i');
                const match = regex.exec(text);

                if (match && !matches.some(m =>
                    (match.index >= m.index && match.index < m.index + m.length) ||
                    (m.index >= match.index && m.index < match.index + match[0].length)
                )) {
                    matches.push({
                        index: match.index,
                        length: match[0].length,
                        term: match[0]
                    });
                    processedTerms.add(term.toLowerCase());
                }
            });

            matches.sort((a, b) => a.index - b.index);

            matches.forEach(match => {

                if (hightlightedMatches.includes(match.term.toLowerCase())) return;

                result += text.slice(lastIndex, match.index);
                result += `<u tabindex="0" class="glossaryWrapper-item js-glossary-item">${match.term}</u>`;
                lastIndex = match.index + match.length;
                hightlightedMatches.push(match.term.toLowerCase());
                //console.log('hightlightedMatches', hightlightedMatches);
            });

            result += text.slice(lastIndex);

            return result;
        };

        // Recursive function to process nodes
        const processNode = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const newContent = processTextNode(node);
                if (newContent !== node.textContent) {
                    const newNode = document.createElement('span');
                    newNode.innerHTML = newContent;
                    node.parentNode.replaceChild(newNode, node);
                }
                // Ignore <a> tags
            } else if (node.nodeType === Node.ELEMENT_NODE && !excludedTags.includes(node.nodeName)) {
                Array.from(node.childNodes).forEach(processNode);
            }
        };

        // Process all nodes
        Array.from(tempDiv.childNodes).forEach(processNode);

        // Update the content with the processed HTML
        content.value = tempDiv.innerHTML;
    }
};

// Function to display the glossary item popup
const showCopy = (target) => {
    // Remove active class from all glossary items
    const glossaryItems = container.value.querySelectorAll('.js-glossary-item');
    glossaryItems.forEach(item => item.classList.remove('is-active'));

    // Find the glossary item data based on the term clicked
    const term = target.textContent;
    const glossaryItem = props.glossary.find(item => item.title.toLowerCase() === term.toLowerCase() || item.variations.includes(term));

    if (glossaryItem) {
        // Set the active glossary item data
        activeGlossaryItem.value = {
            title: glossaryItem.title,
            copy: glossaryItem.copy,
            targetTop: target.offsetTop,
            targetLeft: target.offsetLeft + target.offsetWidth / 2
        };

        // Add active class to the clicked glossary item
        target.classList.add('is-active');
    }
};

/* Not needed with our current implementation */
/*
const reintroduceGlossary = () => {
    if (!glossaryEnabled.value) {
        glossaryEnabled.value = true;
        underlineMatches();
    }
};
*/

// Add a method to handle the glossaryDisabled event
const handleGlossaryDisabled = () => {
    //console.log('Glossary has been disabled. Refreshing content...');
    content.value = originalContent.value;
    glossaryEnabled.value = false;
    hightlightedMatches.value = [];

};

// Hook to run after the component is mounted
onMounted(() => {
    content.value = props.html;
    originalContent.value = props.html;

    // Underline glossary terms in the content
    underlineMatches();
    // Add event listener to handle clicks on dynamically added glossary items
    container.value.addEventListener('click', (event) => {
        if (event.target.classList.contains('js-glossary-item')) {
            showCopy(event.target);
        }
    });
});
</script>
