<template>
    <div :class="`swiper-button-${direction} flex justify-center items-center w-8 !text-orange-default cursor-pointer`" :id="id">
        <span class="sr-only">{{ direction == 'next' ? 'Next' : 'Previous' }}</span>
    </div>
</template>

<script>
    import Icon from './Icon.vue';

    export default {
        props: {
            direction: {
                type: String,
                require: true,
                default: 'next'
            },
            id: {
                type: String,
                require: true
            }
        },
        components: {
            Icon
        },
        data() {
            return {};
        }
    }
</script>