
const countUp = {
    element: document.querySelectorAll('.js-countUp'),
    duration: 500,
    init() {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.countUp(entry.target);
                     // observer.unobserve(entry.target);  // Optionally unobserve after triggering
                }
            });
        }, {
            threshold: 0.5  // Trigger when 50% of the element is visible
        });

        this.element.forEach((el) => {
            observer.observe(el);
        });
    },
    countUp(el) {
        let currentCount = 0;
        const target = parseInt(el.getAttribute('data-target'), 10);
        const speed = this.duration / target;

        const interval = setInterval(() => {
            if (currentCount < target) {
                currentCount += 1;
                el.innerText = currentCount;
            } else {
                clearInterval(interval);
            }
        }, speed);
    },
};

export default countUp;
